import {FC, PropsWithChildren} from 'react'
import {useAppSelector} from '../../store'
import {selectUser} from '../../store/reducers/user'
import {Navigate, useLocation} from 'react-router-dom'
import {paths} from '../../router/paths'
import {tokenStorage} from '../../constants/constants'

interface PrivateRouteProps {}

const PrivateRoute: FC<PropsWithChildren<PrivateRouteProps>> = ({children}) => {
    const location = useLocation()
    const auth = tokenStorage.get()

    if (!auth) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to={paths.login} state={{from: location}} replace />
    }

    return <>{children}</>
}

export default PrivateRoute
