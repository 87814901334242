import {FC, useState} from 'react'
import {Alert, Button, Card, Checkbox, Col, Form, Input, Modal, Row} from 'antd'
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined'
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined'
import classNames from 'classnames'
import {useForm} from 'antd/lib/form/Form'
import FormValues from './types/form-values'
import {initialValues} from './constants/initial-values'
import {useLogin} from '../../store/reducers/api/account'
import {isAuthErrorData} from '../../lib/type-guards/is-auth-error-data'
import {isFetchBaseQueryError} from '../../lib/type-guards/is-fetch-base-query-error'
import {tokenStorage} from '../../constants/constants'

interface LoginProps {
    className?: string
    onSuccess: () => void
    initialMessage?: string
}

const Login: FC<LoginProps> = ({className, onSuccess, initialMessage}) => {
    const [form] = useForm<FormValues>()
    const [login, loginState] = useLogin()

    const extractData = () => {
        const formValues = form.getFieldsValue()
        return formValues
    }
    const [alertMessage, setAlertMessage] = useState<string>(initialMessage || '')

    const onLogin = async () => {
        try {
            await form.validateFields()
            const data = extractData()
            const result = await login(data)

            if ('data' in result) {
                const token = {
                    token: result.data.token,
                    refresh_token: result.data.refresh_token,
                }
                tokenStorage.save(token)
                onSuccess()
            } else if (isFetchBaseQueryError(result.error)) {
                if (isAuthErrorData(result.error.data)) {
                    setAlertMessage(result.error.data.message)
                } else if ('error' in result.error) {
                    setAlertMessage(result.error.error)
                }
            } else {
                setAlertMessage(result.error.message || 'Неизвестная ошибка')
            }
        } catch (e) {
            console.error(e)
        }
    }

    async function loginValidator(r: any, v: string): Promise<string | void> {
        return v.trim() === '' ? Promise.reject('Введите имя пользователя') : Promise.resolve()
    }
    async function passwordValidator(r: any, v: string): Promise<string | void> {
        return v.trim() === '' ? Promise.reject('Введите пароль') : Promise.resolve()
    }

    return (
        <Card className={classNames(className)}>
            <Form form={form} initialValues={initialValues}>
                <Form.Item name={'username'} rules={[{validator: loginValidator}]}>
                    <Input placeholder={'Имя пользователя'} prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item name={'password'} rules={[{validator: passwordValidator}]}>
                    <Input.Password prefix={<LockOutlined />} />
                </Form.Item>
                <Row justify={'space-between'}>
                    <Col>
                        <Form.Item name={'rememberMe'} valuePropName={'checked'}>
                            <Checkbox> Запомнить меня</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                {alertMessage && (
                    <Form.Item>
                        <Alert message={alertMessage} type='error' />
                    </Form.Item>
                )}
                <Button type={'primary'} loading={loginState.isLoading} onClick={onLogin}>
                    Войти
                </Button>
            </Form>
        </Card>
    )
}

export default Login
