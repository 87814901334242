import {createBrowserRouter} from 'react-router-dom'
import Dashboard from '../pages/dashboard/dashboard'
import {paths} from './paths'
import ProductLayout from '../components/product-layout/product-layout'
import Login from '../pages/login/login'
import PrivateRoute from '../components/private-route/private-route'

const calculator = paths.calculator.replace('/', '')
const contracts = paths.contracts.replace('/', '')
const reports = paths.reports.replace('/', '')

const getProductRoutes = (path: string) => {
    const Calculator = require(`../pages${path}/calculator/calculator`).default
    const Contracts = require(`../pages${path}/contracts/contracts`).default
    const Reports = require(`../components/fake/reports`).default

    return {
        path: path,
        element: (
            <PrivateRoute>
                <ProductLayout />
            </PrivateRoute>
        ),
        children: [
            {
                path: calculator,
                element: <Calculator />,
            },
            {
                path: contracts,
                element: <Contracts />,
            },
            {
                path: reports,
                element: <Reports />,
            },
        ],
    }
}

const router = createBrowserRouter([
    {path: '/login', element: <Login />},
    {
        path: '/',
        element: (
            <PrivateRoute>
                <Dashboard />
            </PrivateRoute>
        ),
    },
    getProductRoutes(paths.comfort),
    getProductRoutes(paths.covid),
    getProductRoutes(paths.protection),
    getProductRoutes(paths.touristRussia),
    getProductRoutes(paths.complex),
])

export default router
