import {FC} from 'react'
import {Form, Input} from 'antd'
import {NamePath} from 'antd/lib/form/interface'
import _ from 'lodash'

interface EmailProps {
    namePath: NamePath
}

const Email: FC<EmailProps> = ({namePath}) => {
    const name = _.concat(namePath, 'email')
    return (
        <Form.Item name={name} label={'E-mail'}>
            <Input />
        </Form.Item>
    )
}

export default Email
