import {Modal} from 'antd'
import {tokenStorage} from '../constants/constants'
import {ReactNode} from 'react'

export const useDownloadDoc = () => {
    const showModal = (content: ReactNode) => {
        Modal.error({
            title: 'Ошибка запроса документа',
            content,
        })
    }

    const handleBadRequest = async (response: Response) => {
        const body = await response.json()
        let content = 'Неизвестная ошибка'
        if (body.error) {
            if (typeof body.error === 'string') {
                content = body.error
            } else {
                content = body.error.join(' ')
            }
        } else if (body.errors) {
            if (typeof body.errors === 'string') {
                content = body.errors
            } else {
                content = body.errors.join(' ')
            }
        }
        showModal(content)
    }

    const handleSuccess = async (response: Response): Promise<[Blob, string] | null> => {
        const contentDisposition = response.headers.get('Content-Disposition')

        if (contentDisposition) {
            const contentDispositionList = contentDisposition.split('; ')
            const fileNameItem = contentDispositionList.find(item => item.startsWith('filename'))

            if (fileNameItem) {
                const fileName = fileNameItem.split('=')[1]
                return [await response.blob(), fileName]
            } else {
                showModal('Не указано имя файла')
            }
        } else {
            showModal('Не указан необходимый заголовок')
        }

        return null
    }

    return (url: string) => {
        fetch(url, {
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${tokenStorage.getAccessToken()}`,
            },
        })
            .then(async (response): Promise<[Blob, string] | null> => {
                if (response.status === 200) {
                    return handleSuccess(response)
                } else if (response.status === 400) {
                    await handleBadRequest(response)
                } else {
                    showModal('Неизвестная ошибка')
                }
                return null
            })
            .then(data => {
                if (data === null) return
                const [blob, fileName] = data
                const downloadUrl = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = downloadUrl
                a.download = fileName
                document.body.appendChild(a)
                a.click()
            })
    }
}
