import {Form, Select} from 'antd'
import {NamePath} from 'antd/lib/form/interface'
import _ from 'lodash'
import {FC, useCallback, useEffect, useState} from 'react'
import {useGetSuggestions} from '../../../store/reducers/api/dadata'
import useFormInstance from 'antd/lib/form/hooks/useFormInstance'
import {Rule} from 'antd/lib/form'

interface DadataAddressProps {
    namePath?: NamePath
    fixedCacheKey?: string
    rules?: Rule[]
}

const DadataAddress: FC<DadataAddressProps> = ({namePath = [], rules = [], fixedCacheKey}) => {
    const form = useFormInstance()
    const name = _.concat(namePath, 'address')
    const [getSuggestions, state] = useGetSuggestions({fixedCacheKey})
    const suggestions = state.data?.suggestions || []
    const [searchValue, setSearchValue] = useState<string>('')

    const debouncedSearch = useCallback(
        _.debounce(value => {
            if (!value) return
            getSuggestions({query: value, count: 20})
        }, 500),
        []
    )

    const onSearch = (value: string) => {
        setSearchValue(value)
    }

    useEffect(() => {
        debouncedSearch(searchValue)
    }, [searchValue])

    const onDropdownVisibleChange = (open: boolean) => {
        if (open) {
            const value = form.getFieldValue(name)
            setSearchValue(value || '')
        }
    }

    return (
        <Form.Item name={name} label={'Адрес'} rules={rules}>
            <Select
                filterOption={() => true}
                searchValue={searchValue}
                onSearch={onSearch}
                onDropdownVisibleChange={onDropdownVisibleChange}
                showSearch
                allowClear
            >
                {suggestions.map(item => (
                    <Select.Option value={item.value} key={item.value}>
                        {item.value}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    )
}

export default DadataAddress
