import {Mutex} from 'async-mutex'
import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react'
import {FetchBaseQueryError} from '@reduxjs/toolkit/query'
import showLoginModal from '../render/show-login-modal'
import AuthData, {AuthError} from '../../types/models/auth'
import {domen, tokenStorage} from '../../constants/constants'

const mutex = new Mutex()

export const getCustomFetchBase = (baseUrl: string = '') => {
    let baseQuery = fetchBaseQuery({
        baseUrl,
        credentials: 'include',
        prepareHeaders: headers => {
            const token = tokenStorage.getAccessToken()
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    })

    const customFetchBase: BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
    > = async (args, api, extraOptions) => {
        // Ждем, пока mutex станет доступным.
        // Функция может быть вызвана несколько раз,
        // мы блокируем mutex ниже, если идет повторная авторизация,
        // другие запросы в это время ждут, пока произойдет повторная авторизация.
        // Когда повторная авторизация произошла выполнение других запросов продолжится
        // и для них уже будет актуальная сессия
        await mutex.waitForUnlock()
        let result = await baseQuery(args, api, extraOptions)

        if (result.error && result.error?.status === 401) {
            //  Проверяем если mutex не заблокирован
            if (!mutex.isLocked()) {
                const release = await mutex.acquire()
                const refreshResult = await fetch(`${domen}/api/jwt/refresh`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        refresh_token: tokenStorage.getRefreshToken(),
                    }),
                })

                if (refreshResult.status === 200) {
                    tokenStorage.save((await refreshResult.json()) as AuthData)
                } else {
                    const errorMessage: AuthError = await refreshResult.json()
                    await showLoginModal(errorMessage.message)
                }
                result = await baseQuery(args, api, extraOptions)

                release()
            } else {
                // Если заблокирован, то ждем когда разблокируется и повторяем запрос
                // этот кусок выполняется, если прилетел еще запрос и его тоже редиректнуло на логин,
                // а mutex уже ждет когда повторно авторизуются
                await mutex.waitForUnlock()
                result = await baseQuery(args, api, extraOptions)
            }
        }

        return result
    }

    return customFetchBase
}

export default getCustomFetchBase
