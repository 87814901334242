import {FC} from 'react'
import {Form, Input} from 'antd'
import {NamePath} from 'antd/lib/form/interface'
import _ from 'lodash'

interface MiddleNameProps {
    namePath: NamePath
}

const MiddleName: FC<MiddleNameProps> = ({namePath}) => {
    const name = _.concat(namePath, 'middleName')

    return (
        <Form.Item name={name} label={'Отчество'}>
            <Input />
        </Form.Item>
    )
}

export default MiddleName
