import * as React from 'react'
import {FC} from 'react'
import {Card, Space} from 'antd'
import {spaceSize} from '../../constants/constants'
import ContentHeader from '../content-header/content-header'
import {paths} from '../../router/paths'

interface ReportsProps {}

const Reports: FC<ReportsProps> = () => {
    const productName = 'Любой'

    return (
        <Space direction={'vertical'} size={spaceSize} style={{display: 'flex'}}>
            <ContentHeader
                heading={`Отчеты «${productName}»`}
                productName={productName}
                productPath={paths.comfort}
                currentName={'Отчеты'}
            />
            <Card>CONTENT</Card>
        </Space>
    )
}

export default Reports
