export const paths = {
    login: '/login',

    home: '/',
    comfort: '/comfort',
    covid: '/covid',
    protection: '/protection',
    touristRussia: '/tourist-russia',
    complex: '/complex',
    calculator: '/calculator',
    contracts: '/contracts',
    reports: '/reports',
    terminationBlank: '/termination-blank',
}
