import CalendarOutlined from '@ant-design/icons/lib/icons/CalendarOutlined'
import {DatePicker, Input} from 'antd'
import moment from 'moment'
import {Moment} from 'moment'
import * as React from 'react'
import {RefObject, Component} from 'react'
import * as InputMask from 'react-input-mask'
import './index.less'

export interface DatePickerWithMaskProps {
    value?: Moment | null
    onChange?: (date: Moment | null) => void
    disabled?: boolean
    startMode?: 'date' | 'month' | 'year' | 'decade'
    disabledDate?: (date: Moment) => boolean
}

interface DatePickerWithMaskState {
    dateByMask: string
    open: boolean
    mode: PanelMode
}

type PanelMode = 'date' | 'month' | 'year' | 'decade'

const dateFormat = 'DD.MM.YYYY'
const mask = '99.99.9999'
const empty = '__.__.____'

export default class DatePickerWithMask extends Component<
    DatePickerWithMaskProps,
    DatePickerWithMaskState
> {
    static defaultProps = {
        disabled: false,
        startMode: 'date',
    }

    static getDerivedStateFromProps(
        props: DatePickerWithMaskProps,
        state: DatePickerWithMaskState
    ) {
        const {value} = props
        const {dateByMask} = state

        if (value && dateByMask !== value.format(dateFormat)) {
            return {
                dateByMask: value.format(dateFormat),
            }
        } else if (dateByMask.indexOf('_') === -1 && (value === null || value === undefined)) {
            return {
                dateByMask: '',
            }
        } else {
            return null
        }
    }

    ref: RefObject<any>
    rootRef: RefObject<any>

    constructor(props: DatePickerWithMaskProps) {
        super(props)
        this.state = {
            dateByMask: props.value ? props.value.format(dateFormat) : empty,
            open: false,
            mode: props.startMode as PanelMode,
        }
        this.ref = React.createRef()
        this.rootRef = React.createRef()
        this.onClick = this.onClick.bind(this)
        this.onChangeDateByDatePicker = this.onChangeDateByDatePicker.bind(this)
        this.onPanelChange = this.onPanelChange.bind(this)
        this.onChangeDateByMask = this.onChangeDateByMask.bind(this)
        this.onBodyClick = this.onBodyClick.bind(this)
    }

    onBodyClick(e: any) {
        const dropdown = document.querySelector('.e-date-picker-dropdown')
        if (dropdown) {
            let element = e.target
            do {
                if (
                    element === dropdown ||
                    element.classList.contains('e-open-calendar') ||
                    element.classList.contains('ant-picker-header') ||
                    element.classList.contains('ant-picker-body')
                )
                    return
                element = element.parentNode
            } while (element && element !== document.body)
        }

        this.setState((prevState: any): any => {
            if (prevState.open) return {open: false}
        })
    }

    componentDidMount(): void {
        if (!this.rootRef) return

        document.body.addEventListener('click', this.onBodyClick)
    }

    componentWillUnmount(): void {
        document.body.removeEventListener('click', this.onBodyClick)
    }

    componentDidUpdate(
        prevProps: Readonly<DatePickerWithMaskProps>,
        prevState: Readonly<DatePickerWithMaskState>
    ) {
        if (prevState.dateByMask.indexOf('_') === 9 && this.state.dateByMask === '') {
            this.ref.current.setCursorPosition(0)
        }
    }

    onChangeDateByDatePicker(date: Moment | null) {
        this.setState({
            dateByMask: date ? date.format(dateFormat) : '',
            open: false,
        })
        // @ts-ignore
        this.props.onChange(date)
    }

    onClick() {
        if (this.state.dateByMask === empty) {
            this.ref.current.setCursorPosition(0)
        }
    }

    onPanelChange(value: any, mode: PanelMode) {
        this.setState({mode})
    }

    onChangeDateByMask(e: any) {
        if (e.type === 'change' || e.type === 'blur') {
            const {onChange} = this.props
            const dateByMask = e.target.value
            let mode: PanelMode = 'date'

            if (moment(dateByMask, dateFormat, true).isValid()) {
                const now = moment()
                const value = moment(dateByMask, dateFormat)
                    .set('hour', now.get('hour'))
                    .set('minute', now.get('minute'))
                    .set('second', now.get('second'))
                // @ts-ignore
                onChange(value)
            } else {
                // @ts-ignore
                onChange(null)
                mode = this.props.startMode as PanelMode
            }

            this.setState({dateByMask, mode})
        }
    }

    render() {
        const {open, mode, dateByMask} = this.state
        const {value, disabledDate, disabled} = this.props

        return (
            <div ref={this.rootRef}>
                <Input.Group compact>
                    <DatePicker
                        className={'e-date-picker'}
                        popupClassName={'e-date-picker-dropdown'}
                        showToday={false}
                        open={open}
                        value={value}
                        onChange={this.onChangeDateByDatePicker}
                        mode={mode}
                        // @ts-ignore
                        onPanelChange={this.onPanelChange}
                        disabledDate={disabledDate}
                    />
                    {/* @ts-ignore */}
                    <InputMask
                        mask={mask}
                        value={dateByMask}
                        ref={this.ref}
                        disabled={disabled}
                        onChange={this.onChangeDateByMask}
                        onClick={this.onClick}
                    >
                        {(props: any) => (
                            <Input
                                className={'e-date-mask'}
                                {...props}
                                disabled={disabled}
                                autoComplete={'off'}
                                suffix={
                                    <CalendarOutlined
                                        className={'e-open-calendar'}
                                        onClick={() =>
                                            this.setState(prevState => ({
                                                open: !prevState.open,
                                            }))
                                        }
                                    />
                                }
                            />
                        )}
                    </InputMask>
                </Input.Group>
            </div>
        )
    }
}
