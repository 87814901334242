import {configureStore} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import reducers from './reducers'
import account from './reducers/api/account'
import comfort from './reducers/api/comfort'
import protection from './reducers/api/protection'
import {dadataApi} from './reducers/api/dadata'
import complex from './reducers/api/complex'

const store = configureStore({
    reducer: reducers,
    devTools: true,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .concat(account.middleware)
            .concat(comfort.middleware)
            .concat(protection.middleware)
            .concat(complex.middleware)
            .concat(dadataApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
