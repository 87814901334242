import {FC} from 'react'
import {Space} from 'antd'
import {spaceSize} from '../../../constants/constants'
import ContentHeader from '../../../components/content-header/content-header'
import {paths} from '../../../router/paths'
import Protection from '../../../forms/protection/protection'
import {productName} from '../constants'

interface CalculatorProps {}

const Calculator: FC<CalculatorProps> = () => {
    return (
        <Space direction={'vertical'} size={spaceSize} style={{display: 'flex'}}>
            <ContentHeader
                heading={`Страховой продукт «${productName}»`}
                productName={productName}
                productPath={paths.protection}
                currentName={'Создать'}
            />
            <Protection />
        </Space>
    )
}

export default Calculator
