import createContractsApi from '../../../lib/store/create-contracts-api'
import Complex from '../../../types/models/complex'
import ComplexProgram from '../../../types/models/complex-program'
import {ComplexBody} from '../../../types/requests/complex-body'

const complex = createContractsApi<Complex, ComplexProgram, ComplexBody>({
    reducerPath: 'complexApi',
    type: 'complex',
})

const {endpoints} = complex

export const useCreate = endpoints.create.useMutation
export const useRead = endpoints.read.useQuery
export const useReadList = endpoints.readList.useQuery
export const useReadListLazy = endpoints.readList.useLazyQuery
export const useReadPrograms = endpoints.readPrograms.useQuery
export default complex
