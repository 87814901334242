import {FC} from 'react'
import {Col, Layout, Row} from 'antd'
import Header from '../../components/header/header'
import Card from './components/card'
import {gutterTuple} from '../../constants/constants'
import './index.less'
import {cardItems} from '../../constants/dashboard-items'
const {Content, Footer} = Layout

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = () => {
    return (
        <Layout className={'layout'}>
            <Header />
            <Content style={{padding: '0 50px', margin: '24px 0'}}>
                <Row gutter={gutterTuple}>
                    {cardItems.map(item => (
                        <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={4} key={item.key}>
                            <Card product={item}></Card>
                        </Col>
                    ))}
                </Row>
            </Content>
            <Footer style={{textAlign: 'center'}}>
                ЛК банков и МФО ©2022 Создано ООО РСО «ЕВРОИНС»
            </Footer>
        </Layout>
    )
}

export default Dashboard
