import {Rule} from 'antd/lib/form'

export const serialValidator = (rule: Rule, value: string) => {
    if (!value || !value.trim()) {
        return Promise.reject('Укажите серию')
    } else if (value.length !== 4 || (value.match(/\d/g) || []).length !== 4) {
        return Promise.reject('Введите 4 цифры')
    } else {
        return Promise.resolve()
    }
}
