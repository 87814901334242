import {FC} from 'react'
import {DatePicker, Form} from 'antd'
import _ from 'lodash'
import {NamePath} from 'antd/lib/form/interface'
import {standardRules} from '../../../constants/rules'
import DatePickerWithMask from '../../custom-form-components/date-picker-with-mask'
import moment from 'moment'

interface PassportIssuedDateProps {
    namePath: NamePath
}

const PassportIssuedDate: FC<PassportIssuedDateProps> = ({namePath}) => {
    const name = _.concat(namePath, 'issuedDate')
    const today = moment()

    return (
        <Form.Item name={name} label={'Дата выдачи'} rules={standardRules}>
            <DatePickerWithMask disabledDate={date => date.isAfter(today)} />
        </Form.Item>
    )
}

export default PassportIssuedDate
