import {requestDateFormat} from '../../constants/constants'
import FilterOptions from '../../types/requests/filter-options'
import Filter from '../../types/filter'
import moment from 'moment'

export const useExtractFilter = (): ((form: Filter) => FilterOptions) => {
    return values => {
        const insurerArr = values.insurer
            ? [values.insurer.lastName, values.insurer.firstName].filter(x => x !== undefined)
            : []

        let period = values.period
        if (period !== undefined && period !== null) {
            let createdFrom = moment(period[0])
            let createdTo = moment(period[1])

            if (
                createdTo !== null &&
                createdFrom !== null &&
                createdFrom.isSame(createdTo, 'day')
            ) {
                createdTo = createdTo.add(1, 'days')
            }
            period = [createdFrom, createdTo]
        }

        return {
            filter: {
                bank: values.bank ? values.bank : undefined,
                insurer: insurerArr.length !== 0 ? `%${insurerArr.join(' ').trim()}%` : undefined,
                createdFrom: period ? period[0]?.format(requestDateFormat) || '' : undefined,
                createdTo: period ? period[1]?.format(requestDateFormat) || '' : undefined,
                program: values.program ? values.program : undefined,
                cost: values.cost,
                costCondition: undefined,
                number: values.number ? `%${values.number}%` : undefined,
                perPage: undefined,
                canceled: values.canceled,
            },
        }
    }
}
