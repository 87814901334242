import {FC, useEffect} from 'react'
import {useForm} from 'antd/lib/form/Form'
import {Button, Card, Col, Form, Row, Space} from 'antd'
import {gutter, gutterTuple, today} from '../../constants/constants'
import LastName from '../../components/fields/last-name/last-name'
import FirstName from '../../components/fields/first-name/first-name'
import MiddleName from '../../components/fields/middle-name/middle-name'
import Birthday from '../../components/fields/birthday/birthday'
import Phone from '../../components/fields/phone/phone'
import Address from '../../components/fields/address/address'
import InsurancePremium from '../../components/fields/insurance-premium/insurance-premium'
import CreatePolicy from './create-policy'
import {useReadPrograms} from '../../store/reducers/api/complex'
import Passport from '../../components/passport/passport'
import Development from '../../lib/helpers/components/development'
import FormValues from './types/form-values'
import Period from '../../components/fields/period/period'
import moment from 'moment'
import {initialValues} from './constants/constants'

interface ComplexProps {}

const Complex: FC<ComplexProps> = () => {
    const [form] = useForm()
    const {data = [], isLoading, isSuccess} = useReadPrograms()
    const insurerNamePath = 'insurer'

    useEffect(() => {
        if (isSuccess) {
            form.setFieldsValue({
                insurancePremium: data[0].id,
            })
        }
    }, [isSuccess])

    return (
        <Form form={form} initialValues={initialValues} layout={'vertical'}>
            <Row gutter={gutter}>
                <Col sm={6}>
                    <Card title={'Параметры продукта'}>
                        <Row gutter={gutter}>
                            <Col sm={24}>
                                <InsurancePremium data={data} isLoading={isLoading} />
                            </Col>
                            <Col sm={24}>
                                <Period disabled={true} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm={12}>
                    <Row gutter={gutterTuple}>
                        <Col sm={24}>
                            <Card title={'Страхователь'}>
                                <Row gutter={gutter}>
                                    <Col sm={8}>
                                        <LastName namePath={insurerNamePath} />
                                    </Col>
                                    <Col sm={8}>
                                        <FirstName namePath={insurerNamePath} />
                                    </Col>
                                    <Col sm={8}>
                                        <MiddleName namePath={insurerNamePath} />
                                    </Col>
                                    <Col sm={8}>
                                        <Birthday namePath={insurerNamePath} />
                                    </Col>
                                    <Col sm={8}>
                                        <Phone namePath={insurerNamePath} />
                                    </Col>
                                    <Col sm={24}>
                                        <Address namePath={insurerNamePath} />
                                    </Col>
                                </Row>
                                <Passport namePath={insurerNamePath} />
                            </Card>
                        </Col>
                        <Col sm={24}>
                            <CreatePolicy />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Development>
                <Space>
                    <Button
                        onClick={() => {
                            const values: Partial<FormValues> = {
                                insurer: {
                                    firstName: 'Тест',
                                    lastName: 'Тестов',
                                    middleName: 'Тестович',
                                    birthday: moment('1996-05-22'),
                                    phone: '79629208291',
                                    address: 'г. Москва, ул. Зеленоградская',
                                    passport: {
                                        serial: '1111',
                                        number: '222222',
                                        issuedDate: moment('2023-11-11'),
                                        issuedBy: 'УФМС РФ',
                                    },
                                },
                            }
                            form.setFieldsValue(values)
                        }}
                    >
                        Заполнить
                    </Button>
                    <Button onClick={() => console.log(form.getFieldsValue())}>Данные</Button>
                </Space>
            </Development>
        </Form>
    )
}

export default Complex
