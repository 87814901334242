import {ItemType} from 'antd/lib/menu/hooks/useItems'
import {Link} from 'react-router-dom'
import {paths} from '../router/paths'
import {externalPaths} from './external-paths'
import MenuItemLink from '../components/menu-item-link/menu-item-link'

const menuItems: ItemType[] = [
    {
        key: 'comfort',
        label: <MenuItemLink pathPart={paths.comfort + paths.calculator}>Ваш Уют</MenuItemLink>,
        children: getSubmenuItems(paths.comfort),
    },
    {
        key: 'protection',
        label: (
            <MenuItemLink pathPart={paths.protection + paths.calculator}>
                Финбезопасность
            </MenuItemLink>
        ),
        children: getSubmenuItemsOfProtection(paths.protection),
    },
    {
        key: 'complex',
        label: <MenuItemLink pathPart={paths.complex + paths.calculator}>Комплексный</MenuItemLink>,
        children: getSubmenuItemsOfComplex(paths.complex),
    },
]

function getSubmenuItemsCommon(parentPath: string): ItemType[] {
    const getKey = (key: string) => parentPath + key
    const getTo = (path: string) => parentPath + path
    const getLabel = (to: string, text: string) => <Link to={to}>{text}</Link>
    const list = [
        {path: paths.calculator, text: 'Создать'},
        {path: paths.contracts, text: 'Ваши полисы'},
    ]

    const items = list.map(({path, text}) => ({
        key: getKey(path),
        label: getLabel(getTo(path), text),
    }))

    return items
}

function getSubmenuItemsOfProtection(parentPath: string): ItemType[] {
    const commonItems = getSubmenuItemsCommon(parentPath)
    const getKey = (key: string) => parentPath + key
    const additionalItems = [
        {
            key: getKey(externalPaths.termination),
            label: (
                <a href={externalPaths.termination} target={'_blank'}>
                    Как расторгнуть
                </a>
            ),
        },
        {
            key: getKey('terminationBlank'),
            label: (
                <a href={'/documents/Zayav_ob_otkaze.pdf'} target={'_blank'}>
                    Бланк расторжения
                </a>
            ),
        },
        {
            key: getKey('commonPropertyRules'),
            label: (
                <a
                    href={'/documents/protection/13-3-Pravila_strah_Fin_riskov_№2_dlya_FL_i_UL.pdf'}
                    target={'_blank'}
                >
                    Правила страхования
                </a>
            ),
        },
    ]
    commonItems.push(...additionalItems)
    return commonItems
}

function getSubmenuItemsOfComplex(parentPath: string): ItemType[] {
    const commonItems = getSubmenuItemsCommon(parentPath)
    const getKey = (key: string) => parentPath + key

    const additionalItems = [
        {
            key: getKey(externalPaths.termination),
            label: (
                <a href={externalPaths.termination} target={'_blank'}>
                    Как расторгнуть
                </a>
            ),
        },
        {
            key: getKey('terminationBlank'),
            label: (
                <a href={'/documents/Zayav_ob_otkaze.pdf'} target={'_blank'}>
                    Бланк расторжения
                </a>
            ),
        },
        {
            key: getKey('commonPropertyRules'),
            label: 'Правила страхования',
            children: getSubChildrenComplex(getKey('commonPropertyRules')),
        },
    ]

    commonItems.push(...additionalItems)

    return commonItems
}

function getSubChildrenComplex(key: string): ItemType[] {
    return [
        {
            key: key + 'imu-fiz',
            label: (
                <a href={'/documents/complex/Pravila_strah_IMU_FIZ.pdf'} target={'_blank'}>
                    Имущество физ.лиц
                </a>
            ),
        },
        {
            key: key + 'poterya-raboti',
            label: (
                <a
                    href={'/documents/complex/Pravila_strah_fin_risk_poterya_raboti.pdf'}
                    target={'_blank'}
                >
                    Потеря работы
                </a>
            ),
        },
        {
            key: key + 'ns',
            label: (
                <a href={'/documents/complex/Pravila_strah_Grajdan_NS_B.pdf'} target={'_blank'}>
                    НС
                </a>
            ),
        },
        {
            key: key + 'plat-kart',
            label: (
                <a href={'/documents/complex/Pravila_strah_plat_kart.pdf'} target={'_blank'}>
                    Платежных карт
                </a>
            ),
        },
    ]
}

function getSubmenuItems(parentPath: string): ItemType[] {
    const commonItems = getSubmenuItemsCommon(parentPath)
    const getKey = (key: string) => parentPath + key

    const additionalItems = [
        {
            key: getKey(externalPaths.termination),
            label: (
                <a href={externalPaths.termination} target={'_blank'}>
                    Как расторгнуть
                </a>
            ),
        },
        {
            key: getKey('terminationBlank'),
            label: (
                <a href={'/documents/Zayav_ob_otkaze.pdf'} target={'_blank'}>
                    Бланк расторжения
                </a>
            ),
        },
        {
            key: getKey('commonPropertyRules'),
            label: (
                <a href={externalPaths.commonPropertyRules} target={'_blank'}>
                    Правила страхования
                </a>
            ),
        },
    ]

    commonItems.push(...additionalItems)

    return commonItems
}

export default menuItems
