import {FC} from 'react'
import {Form, Input} from 'antd'
import {NamePath} from 'antd/lib/form/interface'
import _ from 'lodash'
import {standardRules} from '../../../constants/rules'

interface PassportIssuedByProps {
    namePath: NamePath
}

const PassportIssuedBy: FC<PassportIssuedByProps> = ({namePath}) => {
    const name = _.concat(namePath, 'issuedBy')

    return (
        <Form.Item name={name} label={'Выдан'} rules={standardRules}>
            <Input />
        </Form.Item>
    )
}

export default PassportIssuedBy
