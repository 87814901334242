import {createSlice} from '@reduxjs/toolkit'
import account from './api/account'
import {RootState} from '..'

export type User = {username: string}

const key = 'lkb-user'
const item = localStorage.getItem(key)
const initialState: User | null = item && item.length > 0 ? JSON.parse(item) : null

const user = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        resetUser: () => {
            localStorage.removeItem(key)
            return null
        },
    },
    extraReducers: builder => {
        builder.addMatcher(account.endpoints.login.matchFulfilled, (state, action) => {
            const user: User = {
                username: action.meta.arg.originalArgs.username,
            }
            const userJson = JSON.stringify(user)
            localStorage.setItem(key, userJson)
            return user
        })
    },
})

export const {resetUser} = user.actions
export const selectUser = (state: RootState) => state.user
export default user
