import {ColumnsType} from 'antd/lib/table'
import * as React from 'react'
import ContractActionsCell from '../../../../components/contract-actions-cell/contract-actions-cell'

export interface DataItem {
    key: string
    uuid: string
    createdAd: string
    number: string
    branch: string
    premium: string
}

export const columns: ColumnsType<DataItem> = [
    {
        title: 'Создан',
        dataIndex: 'createdAd',
    },
    {
        title: 'Номер полиса',
        dataIndex: 'policyNumber',
    },
    {
        title: 'Филиал',
        dataIndex: 'branch',
    },
    {
        title: 'Премия',
        dataIndex: 'premium',
    },
    {
        title: 'Действия',
        render: (value, record) => <ContractActionsCell record={record} />,
    },
]
