import {domen} from '../../constants/constants'
import {useDownloadDoc} from '../use-download-doc'

export default function useOpenPolicyPdf(appSection: string) {
    const downloadDoc = useDownloadDoc()

    return (uuid: string) => {
        const url = `${domen}/api/${appSection}/policy/${uuid}/download`
        return downloadDoc(url)
    }
}
