import AuthData from '../../types/models/auth'

export class TokenStorage {
    readonly key = 'auth'
    save(data: AuthData) {
        localStorage.setItem(this.key, JSON.stringify(data))
    }
    get(): AuthData | null {
        const auth = localStorage[this.key]
        if (!auth) {
            return null
        }
        return JSON.parse(auth)
    }
    remove() {
        localStorage.removeItem(this.key)
    }

    getAccessToken() {
        return this.get()?.token
    }
    getRefreshToken() {
        return this.get()?.refresh_token
    }
}
