import * as React from 'react'
import {FC} from 'react'
import {Layout} from 'antd'
const {Footer: AntdFooter} = Layout

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
    return (
        <AntdFooter style={{textAlign: 'center'}}>
            ЛК банков и МФО ©2023 Создано ООО РСО «ЕВРОИНС»
        </AntdFooter>
    )
}

export default Footer
