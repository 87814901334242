import {FC} from 'react'
import {Form, Input} from 'antd'
import {NamePath} from 'antd/lib/form/interface'
import _ from 'lodash'
import {numberValidator} from '../../../lib/validators/passport/number-validator'

interface PassportNumberProps {
    namePath: NamePath
}

const PassportNumber: FC<PassportNumberProps> = ({namePath}) => {
    const name = _.concat(namePath, 'number')
    const rules = [{validator: numberValidator, required: true}]

    return (
        <Form.Item name={name} label={'Номер'} rules={rules}>
            <Input />
        </Form.Item>
    )
}

export default PassportNumber
