import * as React from 'react'
import {FC} from 'react'
import {Link} from 'react-router-dom'

interface MenuItemLinkProps {
    pathPart: string
}

const MenuItemLink: FC<React.PropsWithChildren<MenuItemLinkProps>> = ({children, pathPart}) => {
    return (
        <Link to={pathPart}>
            <span className='menu-item__title-links'> {children}</span>
        </Link>
    )
}

export default MenuItemLink
