import useFormInstance from 'antd/lib/form/hooks/useFormInstance'
import {UseCreate} from '../../lib/store/create-contracts-api'
import Contract from '../../types/models/contract'
import {Modal} from 'antd'
import SuccessModalContent from '../../components/success-modal-content/success-modal-content'
import {useNavigate} from 'react-router-dom'
import {useAppSection} from './use-app-section'
import {paths} from '../../router/paths'
import {isFetchBaseQueryError} from '../../lib/type-guards/is-fetch-base-query-error'
import {isBadRequestData} from '../../lib/type-guards/is-bad-request-data'
import {BadRequestData} from '../../types/bad-request-data'
import {Provider} from 'react-redux'
import store from '../../store'
import useOpenPolicyPdf from '../api/use-open-policy-pdf'
import useOpenInvoicePdf from '../api/use-open-invoice-pdf'

type ModalInstance = {
    destroy: () => void
}

export const useOnCreateContract = <M extends Contract, P, B>(
    extract: () => B,
    create: ReturnType<UseCreate<M, P, B>>[0],
    refetchContracts: () => void,
    isLoading: boolean
) => {
    const appSection = useAppSection()
    const navigate = useNavigate()
    const form = useFormInstance()
    const openPolicyPdf = useOpenPolicyPdf(appSection)
    const openInvoicePdf = useOpenInvoicePdf(appSection)

    const navigateToContracts = () => {
        const to = `/${appSection}${paths.contracts}`
        refetchContracts()
        navigate(to)
    }

    const onNew = (modal: ModalInstance) => {
        form.resetFields()
        modal.destroy()
    }

    const onGoToList = (modal: ModalInstance) => {
        modal.destroy()
        navigateToContracts()
    }

    const onDownloadPolicy = (modal: ModalInstance, uuid: string) => {
        form.resetFields()
        modal.destroy()
        openPolicyPdf(uuid)
    }

    const onDownloadInvoice = (modal: ModalInstance, uuid: string) => {
        form.resetFields()
        modal.destroy()
        openInvoicePdf(uuid)
    }

    const showSuccessModal = (uuid: string) => {
        const modal = Modal.success({
            title: 'Полис создан',
            content: (
                <Provider store={store}>
                    <SuccessModalContent
                        uuid={uuid}
                        onNew={() => onNew(modal)}
                        onGoToList={() => onGoToList(modal)}
                        onDownloadPolicy={() => onDownloadPolicy(modal, uuid)}
                        onDownloadInvoice={() => onDownloadInvoice(modal, uuid)}
                        isLoading={isLoading}
                    />
                </Provider>
            ),
        })
    }

    const showErrorModal = (errors: BadRequestData | string) => {
        const errorIsString = typeof errors === 'string'
        Modal.error({
            title: 'Ошибка создания полиса',
            content: <>{errorIsString ? errors : errors.map(e => <div key={e[0]}>{e[1]}</div>)}</>,
        })
    }

    const onCreateContract = async () => {
        try {
            await form.validateFields()
            const body = extract()
            const result = await create(body)
            if ('data' in result) {
                showSuccessModal(result.data.uuid)
            } else if (isFetchBaseQueryError(result.error) && isBadRequestData(result.error.data)) {
                showErrorModal(result.error.data)
            } else if (isFetchBaseQueryError(result.error) && result.error.status === 403) {
                showErrorModal('Обратитесь к куратору')
            } else {
                showErrorModal('Неизвестная ошибка')
            }
        } catch (e) {
            console.error(e)
        }
    }

    return onCreateContract
}
