import {FC} from 'react'
import {DatePicker, Form} from 'antd'
import {russianDateFormat} from '../../../constants/constants'

interface PeriodProps {
    disabled?: boolean
}

const Period: FC<PeriodProps> = ({disabled}) => {
    return (
        <Form.Item name={'period'} label={'Период действия договора'}>
            <DatePicker.RangePicker format={russianDateFormat} disabled={disabled} />
        </Form.Item>
    )
}

export default Period
