import {FC} from 'react'
import {Form, Input} from 'antd'
import _ from 'lodash'
import {NamePath} from 'antd/lib/form/interface'
import {standardRules} from '../../../constants/rules'

interface AddressProps {
    namePath?: NamePath
}

const Address: FC<AddressProps> = ({namePath = []}) => {
    const name = _.concat(namePath, 'address')

    return (
        <Form.Item name={name} label={'Адрес'} rules={standardRules}>
            <Input />
        </Form.Item>
    )
}

export default Address
