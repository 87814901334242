import * as React from 'react'
import {FC} from 'react'
import {Form, Select} from 'antd'

interface InsuranceSumProps {}

const InsuranceSum: FC<InsuranceSumProps> = () => {
    return (
        <Form.Item name={'insuranceSum'} label={'Страховая сумма (руб.)'}>
            <Select>
                <Select.Option value={4000}>4000</Select.Option>
                <Select.Option value={12000}>12000</Select.Option>
            </Select>
        </Form.Item>
    )
}

export default InsuranceSum
