import {Modal} from 'antd'
import Login from '../../forms/login/login'
import {Provider} from 'react-redux'
import store from '../../store'

const showLoginModal = async (errorMessage: string) => {
    await new Promise((resolve, reject) => {
        let modal: ReturnType<typeof Modal.info> | null = null

        const onSuccess = async () => {
            modal?.destroy()
            resolve(null)
        }

        modal = Modal.info({
            title: 'Вы вышли из системы или время сессии истекло, выполните вход.',
            content: (
                <Provider store={store}>
                    <Login onSuccess={onSuccess} initialMessage={errorMessage} />
                </Provider>
            ),
            closable: false,
            icon: null,
            okButtonProps: {style: {display: 'none'}},
        })
    })
}

export default showLoginModal
