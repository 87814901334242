import {Button, Col, Row} from 'antd'
import {FC} from 'react'
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined'
import UnorderedListOutlined from '@ant-design/icons/lib/icons/UnorderedListOutlined'
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'
import ContainerOutlined from '@ant-design/icons/lib/icons/ContainerOutlined'

interface SuccessModalContentProps {
    uuid: string
    onNew: () => void
    onGoToList: () => void
    onDownloadPolicy: () => void
    onDownloadInvoice: () => void
    isLoading: boolean
}

const SuccessModalContent: FC<SuccessModalContentProps> = ({
    onNew,
    onGoToList,
    onDownloadPolicy,
    onDownloadInvoice,
    isLoading,
}) => {
    return (
        <Row gutter={[24, 16]}>
            <Col sm={24}></Col>
            <Col>
                <Button icon={<PlusOutlined />} onClick={onNew}>
                    Новый полис
                </Button>
            </Col>
            <Col>
                <Button icon={<UnorderedListOutlined />} loading={isLoading} onClick={onGoToList}>
                    Список полисов
                </Button>
            </Col>
            <Col>
                <Button icon={<DownloadOutlined />} loading={isLoading} onClick={onDownloadPolicy}>
                    Скачать
                </Button>
            </Col>
            <Col>
                <Button
                    icon={<ContainerOutlined />}
                    loading={isLoading}
                    onClick={onDownloadInvoice}
                >
                    Создать счет
                </Button>
            </Col>
            {/* TODO: Пометить кнопку, которую будут вероятнее всего нажимать первичной */}
            {/* TODO: Возможно отправить на почту или поделиться (возможно сразу на номер, который указан в полисе) */}
        </Row>
    )
}

export default SuccessModalContent
