import {useEffect} from 'react'

export const usePollingInterval = (
    data: {isSuccess: boolean},
    interval: number,
    setPollingInterval: React.Dispatch<React.SetStateAction<number>>
) => {
    useEffect(() => {
        if (data.isSuccess) {
            setPollingInterval(interval)
        }
    }, [data.isSuccess])
}
