import {FC, useEffect} from 'react'
import {Button, Card, Checkbox, Col, Form, Input, Radio, Row, Select, Space} from 'antd'
import {domen, gutter, spaceSize} from '../../constants/constants'
import LastName from '../fields/last-name/last-name'
import FirstName from '../fields/first-name/first-name'
import CreatedDate from '../fields/created-date/created-date'
import {useExtractFilter} from '../../hooks/contracts/use-extract-filter'
import Filter from '../../types/filter'
import qs from 'qs'
import {useForm} from 'antd/lib/form/Form'
import SaveOutlined from '@ant-design/icons/lib/icons/SaveOutlined'
import {useDownloadDoc} from '../../hooks/use-download-doc'

interface ContractContentFilterProps {
    onSearch: (res?: string) => {}
    productPath: string
    isLoading: boolean
    programs: {id: number; options: {name: string}}[]
    isLoadingPrograms: boolean
}

const ContractContentFilter: FC<ContractContentFilterProps> = ({
    onSearch,
    productPath,
    isLoading,
    programs,
    isLoadingPrograms,
}) => {
    const xs = 24
    const sm = 10
    const md = 7
    const lg = 6
    const xl = 5
    const xxl = 3
    const insNamePath = 'insurer'

    const downloadDoc = useDownloadDoc()

    const [form] = useForm<Filter>()

    const extractor = useExtractFilter()

    const defaultValues = {
        canceled: 0,
    }

    const onClick = async () => {
        const param = await getFilters()
        onSearch(param)
    }
    const onReset = async () => {
        form.resetFields()
        const filters = await getFilters()
        onSearch(filters)
    }

    const onSave = async () => {
        const filters = await getFilters()
        const url = `${domen}/api${productPath}/policy/report/xlsx${filters}`
        downloadDoc(url)
    }

    const onKeyDown = (e: {keyCode: number}) => {
        if (e.keyCode === 13) {
            onClick()
        }
    }

    const getFilters = async (): Promise<string> => {
        const values = await form.validateFields()
        const data = extractor(values)
        return qs.stringify(data, {encode: true, addQueryPrefix: true})
    }

    const componentDidMount = async () => {
        const filters = await getFilters()
        onSearch(filters)
    }

    useEffect(() => {
        componentDidMount()
    }, [])

    return (
        <Card>
            <Form
                form={form}
                layout={'vertical'}
                onKeyDown={onKeyDown}
                initialValues={defaultValues}
            >
                <Row gutter={gutter}>
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <LastName namePath={insNamePath} rules={[]} />
                    </Col>
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <FirstName namePath={insNamePath} rules={[]} />
                    </Col>
                    {/*  
                    <Col xs={xs} sm={sm} md={md}>
                        <Form.Item name={'bank'} label={'Банк'}>
                            <Input />
                        </Form.Item>
                     </Col> 
                     */}
                    <Col xs={xs} sm={sm * 2} md={md * 2} lg={lg * 2} xl={xl}>
                        <CreatedDate />
                    </Col>

                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <Form.Item name={'program'} label={'Программа'}>
                            <Select loading={isLoadingPrograms}>
                                {programs.map(program => (
                                    <Select.Option value={program.id} key={program.id}>
                                        {program.options.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <Form.Item name={'cost'} label={'Цена/Премия'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <Form.Item name={'number'} label={'Номер полиса'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <Form.Item name={'canceled'} label={'Аннулирован'}>
                            <Radio.Group size='middle'>
                                <Radio.Button value={1} onClick={onClick}>
                                    Да
                                </Radio.Button>
                                <Radio.Button value={0} onClick={onClick}>
                                    Нет
                                </Radio.Button>
                                <Radio.Button value={undefined} onClick={onClick}>
                                    Все
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <Form.Item label={' '}>
                            <Space size={spaceSize}>
                                <Button type={'primary'} loading={isLoading} onClick={onClick}>
                                    Поиск
                                </Button>
                                <Button onClick={onReset} loading={isLoading}>
                                    Сброс
                                </Button>
                                <Button
                                    loading={isLoading}
                                    onClick={onSave}
                                    icon={<SaveOutlined />}
                                ></Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

export default ContractContentFilter
