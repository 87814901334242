import {FC} from 'react'
import {Form} from 'antd'
import {NamePath} from 'antd/lib/form/interface'
import _ from 'lodash'
import PhoneByMask from '../../custom-form-components/phone'
import {phoneValidator} from '../../../lib/validators/phone-validator'

interface PhoneProps {
    namePath: NamePath
}

const Phone: FC<PhoneProps> = ({namePath}) => {
    const name = _.concat(namePath, 'phone')
    return (
        <Form.Item
            name={name}
            label={'Телефон'}
            rules={[{validator: phoneValidator, required: true}]}
        >
            <PhoneByMask />
        </Form.Item>
    )
}

export default Phone
