import createContractsApi from '../../../lib/store/create-contracts-api'
import Comfort from '../../../types/models/comfort'
import ComfortProgram from '../../../types/models/comfort-program'
import {ComfortBody} from '../../../types/requests/comfort-body'

const comfort = createContractsApi<Comfort, ComfortProgram, ComfortBody>({
    reducerPath: 'comfortApi',
    type: 'comfort',
})

const {endpoints} = comfort

export const useCreate = endpoints.create.useMutation
export const useRead = endpoints.read.useQuery
export const useReadList = endpoints.readList.useQuery
export const useReadListLazy = endpoints.readList.useLazyQuery
export const useReadPrograms = endpoints.readPrograms.useQuery
export default comfort
