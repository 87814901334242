import * as React from 'react'
import {FC} from 'react'
import {useForm} from 'antd/lib/form/Form'
import {Card, Col, Form, Row, Space} from 'antd'
import {gutter, spaceSize} from '../../constants/constants'
import InsuranceSum from '../../components/fields/insurance-sum/insurance-sum'
import Period from '../../components/fields/period/period'
import LastName from '../../components/fields/last-name/last-name'
import FirstName from '../../components/fields/first-name/first-name'
import MiddleName from '../../components/fields/middle-name/middle-name'
import Birthday from '../../components/fields/birthday/birthday'
import Phone from '../../components/fields/phone/phone'
import Address from '../../components/fields/address/address'
import InsurancePremium from '../../components/fields/insurance-premium/insurance-premium'

interface TouristRussiaProps {}

const TouristRussia: FC<TouristRussiaProps> = () => {
    const [form] = useForm()
    const insurerNamePath = 'insurer'
    const insuredNamePath = 'insured'

    return (
        <Form form={form} layout={'vertical'}>
            <Row gutter={gutter}>
                <Col sm={6}>
                    <Card title={'Параметры продукта'}>
                        <Row gutter={gutter}>
                            <Col sm={24}>
                                <InsurancePremium data={[]} isLoading={false} />
                            </Col>
                            <Col sm={24}>
                                <InsuranceSum />
                            </Col>
                            <Col sm={24}>
                                <Period />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm={12}>
                    <Space direction={'vertical'} size={spaceSize} style={{display: 'flex'}}>
                        <Card title={'Страхователь'}>
                            <Row gutter={gutter}>
                                <Col sm={8}>
                                    <LastName namePath={insurerNamePath} />
                                </Col>
                                <Col sm={8}>
                                    <FirstName namePath={insurerNamePath} />
                                </Col>
                                <Col sm={8}>
                                    <MiddleName namePath={insurerNamePath} />
                                </Col>
                                <Col sm={12}>
                                    <Birthday namePath={insurerNamePath} />
                                </Col>
                                <Col sm={12}>
                                    <Phone namePath={insurerNamePath} />
                                </Col>
                                {/* <Col sm={6}>
                                    <PassportSerial />
                                </Col>
                                <Col sm={6}>
                                    <PassportNumber />
                                </Col>
                                <Col sm={6}>
                                    <PassportIssueDate />
                                </Col>
                                <Col sm={6}>
                                    <PassportDepartmentCode />
                                </Col> */}
                                <Col sm={24}>
                                    <Address namePath={insurerNamePath} />
                                </Col>
                            </Row>
                        </Card>
                        <Card title={'Застрахованное лицо'}>
                            <Row gutter={gutter}>
                                <Col sm={8}>
                                    <LastName namePath={insuredNamePath} />
                                </Col>
                                <Col sm={8}>
                                    <FirstName namePath={insuredNamePath} />
                                </Col>
                                <Col sm={8}>
                                    <MiddleName namePath={insuredNamePath} />
                                </Col>
                                <Col sm={12}>
                                    <Birthday namePath={insuredNamePath} />
                                </Col>
                            </Row>
                        </Card>
                    </Space>
                </Col>
                <Col sm={6}></Col>
            </Row>
        </Form>
    )
}

export default TouristRussia
