import ReactDOM from 'react-dom/client'
import ConfigProvider from 'antd/lib/config-provider'
import ru_RU from 'antd/es/locale/ru_RU'
import 'moment/locale/ru'
import App from './components/app/app'
import {Provider} from 'react-redux'
import store from './store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    // <React.StrictMode>
    <ConfigProvider locale={ru_RU}>
        <Provider store={store}>
            <App />
        </Provider>
    </ConfigProvider>
    // </React.StrictMode>
)
