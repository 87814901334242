import '../../common/style/index.less'
import {FC} from 'react'
import {spaceSize} from '../../../constants/constants'
import ContentHeader from '../../../components/content-header/content-header'
import {paths} from '../../../router/paths'
import {Space, Table} from 'antd'
import {productName} from '../constants'
import {useReadListLazy, useReadPrograms} from '../../../store/reducers/api/comfort'
import {useDataSource} from '../../../hooks/contracts/use-data-source'
import {columnsOfContracts} from '../../../constants/columns-of-contracts'
import ContractContentFilter from '../../../components/contract-content-filter/contract-content-filter'

interface ContractsProps {}

const Contracts: FC<ContractsProps> = () => {
    const {data: programs = [], isLoading: isLoadingProgram} = useReadPrograms()

    const [readList, {data, isLoading, isSuccess}] = useReadListLazy()

    const dataSource = useDataSource(data, isSuccess)

    const decor = (record: typeof dataSource[number]) => {
        return record.canceled ? 'row-canceled' : ''
    }

    return (
        <Space direction={'vertical'} size={spaceSize} style={{display: 'flex'}}>
            <ContentHeader
                heading={`Страховые полисы «${productName}»`}
                productName={productName}
                productPath={paths.comfort}
                currentName={'Ваши полисы'}
            />
            <ContractContentFilter
                onSearch={readList}
                productPath={paths.comfort}
                isLoading={isLoading}
                programs={programs}
                isLoadingPrograms={isLoadingProgram}
            />

            <Table
                dataSource={dataSource}
                columns={columnsOfContracts}
                loading={isLoading}
                rowClassName={record => decor(record)}
            />
        </Space>
    )
}

export default Contracts
