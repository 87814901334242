import * as React from 'react'
import {FC} from 'react'
import {Menu as AntdMenu} from 'antd'
import menuItems from '../../constants/menu-items'
import './index.less'

interface MenuProps {}

const Menu: FC<MenuProps> = () => {
    return (
        <AntdMenu
            style={{background: 'white', color: 'inherit', fontSize: '16px', padding: '0 50px'}}
            theme={'dark'}
            mode={'horizontal'}
            defaultSelectedKeys={['2']}
            items={menuItems}
        />
    )
}

export default Menu
