import {FC} from 'react'
import {Form} from 'antd'
import {NamePath} from 'antd/lib/form/interface'
import _ from 'lodash'
import DatePickerWithMask from '../../custom-form-components/date-picker-with-mask'
import {standardRules} from '../../../constants/rules'

interface BirthdayProps {
    namePath: NamePath
}

const Birthday: FC<BirthdayProps> = ({namePath}) => {
    const name = _.concat(namePath, 'birthday')

    return (
        <Form.Item name={name} label={'Дата рождения'} rules={standardRules}>
            {/* <DatePicker style={{width: '100%'}} /> */}
            <DatePickerWithMask />
        </Form.Item>
    )
}

export default Birthday
