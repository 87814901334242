import {FC, useEffect, useState} from 'react'
import {Button} from 'antd'
import {useCreate, useRead, useReadList} from '../../store/reducers/api/complex'
import {useExtract} from './hooks/use-extract'
import {useOnCreateContract} from '../../hooks/contracts/use-on-create-contract'
import Complex from '../../types/models/complex'
import ComplexProgram from '../../types/models/complex-program'
import {usePollingInterval} from '../../hooks/use-polling-interval'
import {ComplexBody} from '../../types/requests/complex-body'

interface CreatePolicyProps {}

const CreatePolicy: FC<CreatePolicyProps> = () => {
    const extract = useExtract()
    const {refetch} = useReadList()
    const [pollingInterval, setPollingInterval] = useState<number>(0)
    const [create, createState] = useCreate()
    const uuid = createState.data?.uuid || ''

    const readState = useRead(uuid, {skip: !uuid, pollingInterval})

    usePollingInterval(readState, 0, setPollingInterval)
    usePollingInterval(createState, 3000, setPollingInterval)

    const onCreate = useOnCreateContract<Complex, ComplexProgram, ComplexBody>(
        extract,
        create,
        refetch,
        readState.isLoading
    )

    return (
        <Button type={'primary'} loading={createState.isLoading} onClick={onCreate}>
            Создать полис
        </Button>
    )
}

export default CreatePolicy
