export interface CardItem {
    label: string
    key: string
}

export const cardItems: CardItem[] = [
    {
        label: 'Ваш Уют', // Бывший "Комфорт"
        key: 'comfort',
    },
    {
        label: 'Финбезопасность', // Бывший 'Защита дохода'
        key: 'protection',
    },
    {
        label: 'Комплексный',
        key: 'complex',
    },
]
