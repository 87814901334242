import createContractsApi from '../../../lib/store/create-contracts-api'
import Protection from '../../../types/models/protection'
import ProtectionProgram from '../../../types/models/protection-program'
import {ProtectionBody} from '../../../types/requests/protection-body'

const protection = createContractsApi<Protection, ProtectionProgram, ProtectionBody>({
    reducerPath: 'protectionApi',
    type: 'protection',
})

const {endpoints} = protection

export const useCreate = endpoints.create.useMutation
export const useRead = endpoints.read.useQuery
export const useReadList = endpoints.readList.useQuery
export const useReadListLazy = endpoints.readList.useLazyQuery
export const useReadPrograms = endpoints.readPrograms.useQuery
export default protection
