import {FC} from 'react'
import {Col, Layout, Row, Tooltip} from 'antd'
import Menu from '../menu/menu'
import LogoutOutlined from '@ant-design/icons/lib/icons/LogoutOutlined'
import {useAppDispatch, useAppSelector} from '../../store'
import {User, resetUser, selectUser} from '../../store/reducers/user'
import {Link, useNavigate} from 'react-router-dom'
import {tokenStorage} from '../../constants/constants'
import {paths} from '../../router/paths'

const {Header: AntdHeader} = Layout

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
    const user = useAppSelector(selectUser) as User
    const dispatch = useAppDispatch()

    const navigate = useNavigate()
    const navigateToLogin = () => navigate(paths.login)

    const onLogout = async () => {
        tokenStorage.remove()
        navigateToLogin()
        dispatch(resetUser())
    }

    return (
        <>
            <AntdHeader style={{color: 'white', fontSize: '16px'}}>
                <Row justify={'space-between'}>
                    <Col>
                        <Link to={'/'}>
                            <img
                                style={{display: 'block', height: '64px'}}
                                src={'/logo_white.svg'}
                                alt={'ООО РСО ЕВРОИНС'}
                            />
                        </Link>
                    </Col>
                    <Col>Страхование клиентов банков и МФО</Col>
                    <Col>
                        {user.username}{' '}
                        <Tooltip title={'Выйти'}>
                            <LogoutOutlined style={{cursor: 'pointer'}} onClick={onLogout} />
                        </Tooltip>
                    </Col>
                </Row>
            </AntdHeader>
            <Menu />
        </>
    )
}

export default Header
