import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'
import Dadata from '../../../types/models/dadata/dadata'
import {DadataBody} from '../../../types/requests/dadata/dadata'

export const dadataApi = createApi({
    reducerPath: 'dadata',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Token 7977a2f68476d3e36e334f80478e72773690b72a',
        },
    }),
    tagTypes: [],
    endpoints: build => ({
        getSuggestions: build.mutation<Dadata, DadataBody>({
            query: body => ({
                url: '/suggest/address',
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const useGetSuggestions = dadataApi.endpoints.getSuggestions.useMutation
