import moment from 'moment'
import FormValues from '../types/form-values'

const beginDate = moment().add(11, 'days')
const endDate = moment(beginDate).add(1, 'year').subtract(1, 'days')
export const comfortSuggestionsCacheKey = 'comfortSuggestionsCacheKey'

export const initialValues: Partial<FormValues> = {
    // insurancePremium: 1,
    period: [beginDate, endDate],
    address: '',
    insurer: {
        firstName: '',
        lastName: '',
        middleName: '',
        birthday: null,
        phone: '',
        passport: {
            serial: '',
            number: '',
            issuedDate: null,
            issuedBy: '',
        },
    },
}
