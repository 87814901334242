import moment from 'moment'
import FormValues from '../types/form-values'

const beginDate = moment().add(15, 'days')
const endDate = moment(beginDate).add(1, 'year').subtract(1, 'days')
export const comfortSuggestionsCacheKey = 'comfortSuggestionsCacheKey'

export const initialValues: Partial<FormValues> = {
    period: [beginDate, endDate],
    insurer: {
        firstName: '',
        lastName: '',
        middleName: '',
        birthday: null,
        phone: '',
        address: '',
        email: '',
        passport: {
            serial: '',
            number: '',
            issuedDate: null,
            issuedBy: '',
        },
    },
}
