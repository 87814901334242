import {FC} from 'react'
import {Form, Input} from 'antd'
import {NamePath} from 'antd/lib/form/interface'
import _ from 'lodash'
import {serialValidator} from '../../../lib/validators/passport/serial-validator'

interface PassportSerialProps {
    namePath: NamePath
}

const PassportSerial: FC<PassportSerialProps> = ({namePath}) => {
    const name = _.concat(namePath, 'serial')
    const rules = [{validator: serialValidator, required: true}]

    return (
        <Form.Item name={name} label={'Серия'} rules={rules}>
            <Input />
        </Form.Item>
    )
}

export default PassportSerial
