import * as React from 'react'
import {Component} from 'react'
import Input from 'antd/lib/input'
import 'antd/lib/input/style'
import * as InputMask from 'react-input-mask'

interface PhonePropsInterface {
    disabled?: boolean
    onChange?: (e: any) => () => void
    style?: object
    className?: string
}

interface PhoneStateInterface {
    value: string
}

class Phone extends Component<PhonePropsInterface, PhoneStateInterface> {
    constructor(props: any) {
        super(props)

        this.state = {
            value: props.value || '+7 (___) ___-__-__',
        }
    }

    static defaultProps = {
        disabled: false,
        style: {},
        className: '',
    }

    static getDerivedStateFromProps(nextProps: any) {
        if ('value' in nextProps) {
            return {
                ...(nextProps.value ? {value: nextProps.value} : {}),
            }
        }
        return null
    }

    changeValue = (e: any) => {
        if (e.type === 'change') {
            // @ts-ignore
            this.setState({value: e.target.value}, this.props.onChange(e.target.value))
        }
    }

    render() {
        const {disabled} = this.props,
            {value} = this.state,
            {style} = this.props,
            {className} = this.props

        return (
            // @ts-ignore
            <InputMask
                mask='+7 (999) 999-99-99'
                value={value}
                onChange={this.changeValue}
                disabled={disabled}
            >
                {(props: any) => (
                    <Input
                        {...props}
                        type={'tel'}
                        disabled={disabled}
                        style={style}
                        className={className}
                    />
                )}
            </InputMask>
        )
    }
}

export default Phone
