import * as React from 'react'
import {FC} from 'react'
import {Space, Table} from 'antd'
import {spaceSize} from '../../../constants/constants'
import ContentHeader from '../../../components/content-header/content-header'
import {productName} from '../constants'
import {paths} from '../../../router/paths'
import {columns} from './constants/columns'
import {getRowKeyAsIndex} from '../../../lib/render/get-row-key-as-index'

interface ContractsProps {}

const Contracts: FC<ContractsProps> = () => {
    const dataSource = Array(20).fill({
        key: '1',
        createdAd: '21.09.2022',
        policyNumber: '245365',
        branch: '????',
        premium: '5000',
        insured: 'Иванов Г. Д.',
    })

    return (
        <Space direction={'vertical'} size={spaceSize} style={{display: 'flex'}}>
            <ContentHeader
                heading={`Страховые полисы «${productName}»`}
                productName={productName}
                productPath={paths.touristRussia}
                currentName={'Ваши полисы'}
            />
            <Table
                dataSource={dataSource}
                columns={columns}
                rowKey={getRowKeyAsIndex} // todo - исправить
            />
        </Space>
    )
}

export default Contracts
