import {combineReducers} from 'redux'
import account from './api/account'
import comfort from './api/comfort'
import protection from './api/protection'
import user from './user'
import {dadataApi} from './api/dadata'
import complex from './api/complex'

const reducers = combineReducers({
    user: user.reducer,
    [account.reducerPath]: account.reducer,
    [comfort.reducerPath]: comfort.reducer,
    [protection.reducerPath]: protection.reducer,
    [complex.reducerPath]: complex.reducer,
    [dadataApi.reducerPath]: dadataApi.reducer,
})

export default reducers
