import {Rule} from 'antd/lib/form'
import {useGetSuggestions} from '../../store/reducers/api/dadata'

export const useAddressFlatValidator = ({fixedCacheKey}: {fixedCacheKey: string}) => {
    const [, state] = useGetSuggestions({fixedCacheKey})
    const suggestions = state.data?.suggestions || []

    return async (rule: Rule, value: string) => {
        const suggestion = suggestions.find(item => item.value === value)
        if (!suggestion?.data.flat) {
            return Promise.reject('Укажите адрес с точностью до квартиры')
        }
        return Promise.resolve()
    }
}
