import {Col, Divider, Row} from 'antd'
import {FC} from 'react'
import {gutter} from '../../constants/constants'
import {NamePath} from 'antd/lib/form/interface'
import _ from 'lodash'
import PassportSerial from '../fields/passport-serial/passport-serial'
import PassportNumber from '../fields/passport-number/passport-number'
import PassportIssuedDate from '../fields/passport-issued-date/passport-issued-date'
import PassportIssuedBy from '../fields/passport-issued-by/passport-issued-by'

interface PassportProps {
    namePath: NamePath
}

const Passport: FC<PassportProps> = ({namePath}) => {
    const name = _.concat(namePath, 'passport')

    return (
        <Row gutter={gutter}>
            <Col sm={24}>
                <Divider orientation={'left'}>Паспортные данные</Divider>
            </Col>
            <Col sm={8}>
                <PassportSerial namePath={name} />
            </Col>
            <Col sm={8}>
                <PassportNumber namePath={name} />
            </Col>
            <Col sm={8}>
                <PassportIssuedDate namePath={name} />
            </Col>
            <Col sm={24}>
                <PassportIssuedBy namePath={name} />
            </Col>
        </Row>
    )
}

export default Passport
