import Passport from '../../../types/models/passport'
import {PassportFormValues} from '../types/form-values'
import {requestDateFormat} from '../../../constants/constants'

export const useExtractPassport = (): ((person: {passport: PassportFormValues}) => Passport) => {
    return person => {
        const values: PassportFormValues = person.passport

        return {
            serial: values.serial,
            number: values.number,
            issuedDate: values.issuedDate?.format(requestDateFormat) || '',
            issuedBy: values.issuedBy,
        }
    }
}
