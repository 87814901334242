import {FC} from 'react'
import {DatePicker, Form} from 'antd'
import _ from 'lodash'
import {DatePickerWithMaskProps} from '../../custom-form-components/date-picker-with-mask/index'
import {russianDateFormat} from '../../../constants/constants'

interface CreatedDateProps extends DatePickerWithMaskProps {}

const CreatedDate: FC<CreatedDateProps> = ({}) => {
    return (
        <Form.Item name={'period'} label={'Создан'}>
            <DatePicker.RangePicker format={russianDateFormat} style={{width: '100%'}} />
        </Form.Item>
    )
}

export default CreatedDate
