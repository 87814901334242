import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {LoginBody} from '../../../types/requests/login-body'
import {domen} from '../../../constants/constants'
import AuthData from '../../../types/models/auth'

const account = createApi({
    reducerPath: 'accountApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${domen}`,
        credentials: 'include',
    }),
    endpoints: build => ({
        login: build.mutation<AuthData, LoginBody>({
            query: body => ({
                url: '/api/jwt/login_check',
                method: 'POST',
                body,
            }),
        }),
    }),
})

const {endpoints} = account

export const useLogin = endpoints.login.useMutation
export default account
