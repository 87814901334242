import {Gutter} from 'antd/lib/grid/row'
import moment from 'moment'
import {TokenStorage} from '../lib/class/token-storage'

export const gutter: Gutter = 24
export const gutterTuple: [Gutter, Gutter] = [24, 24]
export const spaceSize = 24
export const domen = process.env.REACT_APP_API_ORIGIN
export const requestDateFormat = 'YYYY-MM-DD'
export const russianDateFormat = 'DD.MM.YYYY'

export const yesterday = moment().subtract(1, 'days')
export const today = moment()

export const ENV_IS_DEV = process.env.NODE_ENV === 'development'

export const tokenStorage = new TokenStorage()
