import {Card, Typography} from 'antd'
import * as React from 'react'
import {FC, ReactNode} from 'react'
import ProductBreadcrumb from '../product-breadcrumb/product-breadcrumb'

interface ContentHeaderProps {
    heading: ReactNode
    productName: string // Ваш Уют
    productPath: string // /comfort
    currentName: string // Создать
}

const ContentHeader: FC<ContentHeaderProps> = ({
    heading,
    productName,
    productPath,
    currentName,
}) => {
    return (
        <Card>
            <ProductBreadcrumb
                productName={productName}
                productPath={productPath}
                currentName={currentName}
            />
            <Typography.Title>{heading}</Typography.Title>
        </Card>
    )
}

export default ContentHeader
