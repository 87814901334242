import useFormInstance from 'antd/lib/form/hooks/useFormInstance'
import FormValues from '../types/form-values'
import {requestDateFormat} from '../../../constants/constants'
import {useExtractPassport} from '../../common/hooks/use-extract-passport'
import {ComplexBody} from '../../../types/requests/complex-body'

export const useExtract = (): (() => ComplexBody) => {
    const form = useFormInstance<FormValues>()
    const extractPassport = useExtractPassport()

    return () => {
        const values = form.getFieldsValue()
        const passport = extractPassport(values.insurer)

        return {
            program: values.insurancePremium,
            beginDate: values.period[0]?.format(requestDateFormat) || '',
            insurer: {
                ...values.insurer,
                birthday: values.insurer.birthday?.format(requestDateFormat) || '',
                passport,
                phone: values.insurer.phone.match(/\d/g)?.join('') || '',
            },
        }
    }
}
