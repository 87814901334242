import * as React from 'react'
import {FC} from 'react'
import Header from '../../components/header/header'
import {Layout} from 'antd'
import {Outlet} from 'react-router-dom'
import Footer from '../footer/footer'
import './index.less'

const {Content} = Layout

interface ProductLayoutProps {}

const ProductLayout: FC<ProductLayoutProps> = () => {
    return (
        <Layout className={'product-layout'}>
            <Header />
            <Content style={{padding: '0 50px', marginTop: '24px'}}>
                <Outlet />
            </Content>
            <Footer />
        </Layout>
    )
}

export default ProductLayout
