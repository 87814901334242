import {Rule} from 'antd/lib/form'

export const numberValidator = (rule: Rule, value: string) => {
    if (!value || !value.trim()) {
        return Promise.reject('Укажите номер')
    } else if (value.length !== 6 || (value.match(/\d/g) || []).length !== 6) {
        return Promise.reject('Введите 6 цифр')
    } else {
        return Promise.resolve()
    }
}
