import {FC} from 'react'
import {Form, Input} from 'antd'
import {NamePath} from 'antd/lib/form/interface'
import _ from 'lodash'
import {standardRules} from '../../../constants/rules'
import {Rule} from 'antd/lib/form'

interface LastNameProps {
    namePath: NamePath
    rules?: Rule[]
}

const LastName: FC<LastNameProps> = ({namePath, rules = standardRules}) => {
    const name = _.concat(namePath, 'lastName')

    return (
        <Form.Item name={name} label={'Фамилия'} rules={rules}>
            <Input />
        </Form.Item>
    )
}

export default LastName
