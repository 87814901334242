import {Form, Input} from 'antd'
import {NamePath} from 'antd/lib/form/interface'
import _ from 'lodash'
import {FC} from 'react'
import {standardRules} from '../../../constants/rules'
import {Rule} from 'antd/lib/form'

interface FirstNameProps {
    namePath: NamePath
    rules?: Rule[]
}

const FirstName: FC<FirstNameProps> = ({namePath, rules = standardRules}) => {
    const name = _.concat(namePath, 'firstName')
    return (
        <Form.Item name={name} label={'Имя'} rules={rules}>
            <Input />
        </Form.Item>
    )
}

export default FirstName
