import {FC} from 'react'
import {Link} from 'react-router-dom'
import './index.less'
import {CardItem} from '../../../constants/dashboard-items'
import {paths} from '../../../router/paths'

interface CardProps {
    product: CardItem
}

const Card: FC<CardProps> = ({product}) => {
    return (
        <div className='dashboard__card'>
            <span className='dashboard__card-title'>{product.label}</span>
            <Link to={`/${product.key}${paths.calculator}`}>
                <span className='dashboard__card-link'>Создать</span>
            </Link>
            <Link to={`/${product.key}${paths.contracts}`}>
                <span className='dashboard__card-link'>Ваши полисы</span>
            </Link>
        </div>
    )
}

export default Card
