import Contract from '../../types/models/contract'

export const useDataSource = (data: DataSource = {policies: []}, isSuccess: boolean) => {
    return isSuccess
        ? data.policies.map(item => ({
              key: item.uuid,
              createdAt: item.created,
              program: item.program.options.name,
              uuid: item.uuid,
              number: item.number,
              beginDate: item.beginDate,
              endDate: item.endDate,
              premium: item.program.premium,
              insurer: `${item.insurer.lastName} ${item.insurer.firstName} ${item.insurer.middleName}`,
              bank: item.bank,
              canceled: item.canceled,
          }))
        : []
}

export type DataSource = Data | undefined

interface Data {
    policies: Policy[]
}

interface Policy extends Contract {
    program: Program
    beginDate: string
    endDate: string
    bank: string
    canceled: boolean
    insurer: {
        lastName: string
        firstName: string
        middleName: string
    }
}
interface Program {
    premium: number
    options: {
        name: string
    }
}
