import * as React from 'react'
import {FC} from 'react'
import {Breadcrumb} from 'antd'
import {Link} from 'react-router-dom'
import {paths} from '../../router/paths'

interface ProductBreadcrumbProps {
    productName: string // Ваш Уют
    productPath: string // /comfort
    currentName: string // Создать
}

const ProductBreadcrumb: FC<ProductBreadcrumbProps> = ({productName, productPath, currentName}) => {
    return (
        <Breadcrumb style={{margin: '16px 0'}}>
            <Breadcrumb.Item>
                <Link to={paths.home}>Главная</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{productName}</Breadcrumb.Item>
            <Breadcrumb.Item>{currentName}</Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default ProductBreadcrumb
