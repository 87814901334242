import {FC} from 'react'
import {Form, Select} from 'antd'
import {standardRules} from '../../../constants/rules'

interface InsurancePremiumProps {
    data: {id: number; premium: number}[]
    isLoading: boolean
}

const InsurancePremium: FC<InsurancePremiumProps> = ({data, isLoading}) => {
    return (
        <Form.Item
            name={'insurancePremium'}
            label={'Страховая премия (руб.)'}
            rules={standardRules}
        >
            <Select loading={isLoading}>
                {data.map(program => (
                    <Select.Option value={program.id} key={program.id}>
                        {program.premium}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    )
}

export default InsurancePremium
