import useFormInstance from 'antd/lib/form/hooks/useFormInstance'
import {ComfortBody} from '../../../types/requests/comfort-body'
import FormValues from '../types/form-values'
import {requestDateFormat} from '../../../constants/constants'
import {useExtractPassport} from '../../common/hooks/use-extract-passport'

export const useExtract = (): (() => ComfortBody) => {
    const form = useFormInstance<FormValues>()
    const extractPassport = useExtractPassport()

    return () => {
        const values = form.getFieldsValue()
        const insurer = values.insurer
        const passport = extractPassport(insurer)

        return {
            program: values.insurancePremium,
            beginDate: values.period[0]?.format(requestDateFormat) || '',
            endDate: values.period[1]?.format(requestDateFormat) || '',
            type: 'flat',
            address: values.address,
            insurer: {
                firstName: insurer.firstName,
                lastName: insurer.lastName,
                middleName: insurer.middleName,
                phone: insurer.phone.match(/\d/g)?.join('') || '',
                birthday: insurer.birthday?.format(requestDateFormat) || '',
                passport,
            },
        }
    }
}
