import * as React from 'react'
import {FC} from 'react'
import {Space, Typography} from 'antd'
import LoginForm from '../../forms/login/login'
import './index.less'
import {useNavigate} from 'react-router-dom'
import {paths} from '../../router/paths'
const {Title} = Typography

interface LoginProps {}

const Login: FC<LoginProps> = () => {
    const navigate = useNavigate()
    const navigateToHome = () => navigate(paths.home)

    return (
        <div className={'login-page'}>
            <Space className={'login-page__content'} direction={'vertical'} size={48}>
                <img className={'login-page__logo'} src={'/logo.svg'} alt={'ООО РСО ЕВРОИНС'} />
                <div className={'login-page__titles'}>
                    <Title>Личный кабинет</Title>
                    <Title className={'login-page__title_level_2'} level={2}>
                        Страхование клиентов банков и МФО
                    </Title>
                </div>
                <LoginForm className={'login-page__form'} onSuccess={navigateToHome} />
            </Space>
        </div>
    )
}

export default Login
