import {FC} from 'react'
import {Button, Space, Tooltip} from 'antd'
import ContainerOutlined from '@ant-design/icons/lib/icons/ContainerOutlined'
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined'
import {useAppSection} from '../../hooks/contracts/use-app-section'
import useOpenPolicyPdf from '../../hooks/api/use-open-policy-pdf'
import useOpenInvoicePdf from '../../hooks/api/use-open-invoice-pdf'

interface ContractActionsCellProps {
    record: {uuid: string}
}

const ContractActionsCell: FC<ContractActionsCellProps> = ({record}) => {
    const appSection = useAppSection()
    const openPolicyPdf = useOpenPolicyPdf(appSection)
    const openInvoicePdf = useOpenInvoicePdf(appSection)

    const onDownloadPolicy = () => {
        openPolicyPdf(record.uuid)
    }
    const onDownloadInvoice = () => {
        openInvoicePdf(record.uuid)
    }

    return (
        <Space size={8}>
            <Tooltip title={'Скачать полис'}>
                <Button icon={<DownloadOutlined />} onClick={onDownloadPolicy} />
            </Tooltip>
            <Tooltip title={'Создать счет'}>
                <Button icon={<ContainerOutlined />} onClick={onDownloadInvoice} />
            </Tooltip>
        </Space>
    )
}

export default ContractActionsCell
