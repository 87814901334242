import {FC, useEffect} from 'react'
import {useForm} from 'antd/lib/form/Form'
import {Button, Card, Col, Divider, Form, Row} from 'antd'
import {gutter, gutterTuple} from '../../constants/constants'
import Period from '../../components/fields/period/period'
import LastName from '../../components/fields/last-name/last-name'
import FirstName from '../../components/fields/first-name/first-name'
import MiddleName from '../../components/fields/middle-name/middle-name'
import Birthday from '../../components/fields/birthday/birthday'
import Phone from '../../components/fields/phone/phone'
import InsurancePremium from '../../components/fields/insurance-premium/insurance-premium'
import FormValues from './types/form-values'
import CreatePolicy from './create-policy'
import {comfortSuggestionsCacheKey, initialValues} from './constants/constants'
import {useReadPrograms} from '../../store/reducers/api/comfort'
import moment from 'moment'
import DadataAddress from '../../components/fields/dadata-address/dadata-address'
import {useAddressFlatValidator} from '../../hooks/validators/use-address-flat-validator'
import Passport from '../../components/passport/passport'
import Development from '../../lib/helpers/components/development'

interface ComfortProps {}

const Comfort: FC<ComfortProps> = () => {
    const [form] = useForm<FormValues>()
    const {data = [], isLoading, isSuccess} = useReadPrograms()
    const insurerNamePath = 'insurer'
    const addressValidator = useAddressFlatValidator({fixedCacheKey: comfortSuggestionsCacheKey})

    // @ts-ignore
    window.eform = form

    useEffect(() => {
        if (isSuccess) {
            form.setFieldsValue({
                insurancePremium: data[0].id,
            })
        }
    }, [isSuccess])

    return (
        <Form form={form} initialValues={initialValues} layout={'vertical'}>
            <Row gutter={gutter}>
                <Col sm={6}>
                    <Card title={'Параметры продукта'}>
                        <Row gutter={gutter}>
                            <Col sm={24}>
                                <InsurancePremium data={data} isLoading={isLoading} />
                            </Col>
                            <Col sm={24}>
                                <Period disabled={true} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm={12}>
                    <Row gutter={gutterTuple}>
                        <Col sm={24}>
                            <Card title={'Страхователь'}>
                                <Row gutter={gutter}>
                                    <Col sm={8}>
                                        <LastName namePath={insurerNamePath} />
                                    </Col>
                                    <Col sm={8}>
                                        <FirstName namePath={insurerNamePath} />
                                    </Col>
                                    <Col sm={8}>
                                        <MiddleName namePath={insurerNamePath} />
                                    </Col>
                                    <Col sm={8}>
                                        <Birthday namePath={insurerNamePath} />
                                    </Col>
                                    <Col sm={8}>
                                        <Phone namePath={insurerNamePath} />
                                    </Col>
                                </Row>
                                <Passport namePath={insurerNamePath} />
                            </Card>
                        </Col>
                        <Col sm={24}>
                            <Card title={'Объект страхования'}>
                                {/* <Address /> */}
                                <DadataAddress
                                    rules={[{validator: addressValidator, required: true}]}
                                    fixedCacheKey={comfortSuggestionsCacheKey}
                                />
                            </Card>
                        </Col>
                        <Col sm={24}>
                            <CreatePolicy />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Development>
                <Button
                    onClick={() => {
                        const values: Partial<FormValues> = {
                            insurancePremium: 1,
                            // period: [moment().add(1, 'days'), moment()],
                            address: 'г Москва, ул Россошанская, д 7 к 1, кв 2',
                            insurer: {
                                firstName: 'Тест',
                                lastName: 'Тестов',
                                middleName: 'Тестович',
                                birthday: moment('1996-05-22'),
                                phone: '+79629208291',
                                // address: 'г. Москва, ул. Зеленоградская',
                                passport: {
                                    serial: '3333',
                                    number: '444444',
                                    issuedBy: 'ТП В Г. МОСКВА',
                                    issuedDate: moment('2015-05-20'),
                                },
                            },
                        }
                        form.setFieldsValue(values)
                    }}
                >
                    Заполнить
                </Button>
                <Button onClick={() => console.log(form.getFieldsValue())}>Данные</Button>
            </Development>
        </Form>
    )
}

export default Comfort
