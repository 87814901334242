import {createApi} from '@reduxjs/toolkit/query/react'
import {domen} from '../../constants/constants'
import Contract from '../../types/models/contract'
import {ContractsList} from '../../types/models/contracts-list'
import getCustomFetchBase from './custom-fetch-base'

type CreationResult = {success: true; uuid: string}

type ContractsApiConfig = {
    reducerPath: string
    type: string
}

const createContractsApi = <T extends Contract, P, B>(config: ContractsApiConfig) => {
    const {reducerPath, type} = config
    return createApi({
        reducerPath,
        baseQuery: getCustomFetchBase(`${domen}/api/${type}`),
        endpoints: build => ({
            create: build.mutation<CreationResult, B>({
                query: body => ({
                    url: '/policy/create',
                    method: 'POST',
                    body,
                }),
            }),
            read: build.query<T, string>({
                query: uuid => ({
                    url: `/policy/${uuid}`,
                }),
            }),
            readList: build.query<ContractsList<T>, string | void>({
                query: (str = '') => ({
                    url: `/policy${str}`,
                }),
                serializeQueryArgs: () => 'readListKey',
            }),
            readPrograms: build.query<P[], void>({
                query: () => ({
                    url: '/programs',
                }),
            }),
        }),
    })
}

export default createContractsApi

export type UseCreate<T extends Contract, P, B> = ReturnType<
    typeof createContractsApi<T, P, B>
>['endpoints']['create']['useMutation']
