import {ColumnsType} from 'antd/lib/table'
import ContractActionsCell from '../components/contract-actions-cell/contract-actions-cell'

export interface DataItem {
    key: string
    uuid: string
    createdAt: string
    number: string
    beginDate: string
    program: string
    endDate: string
    premium: number
    insurer: string
    bank: string
    canceled: boolean
}

export const columnsOfContracts: ColumnsType<DataItem> = [
    {
        title: 'Номер полиса',
        dataIndex: 'number',
    },
    {
        title: 'Создан',
        dataIndex: 'createdAt',
    },

    {
        title: 'Начало действия',
        dataIndex: 'beginDate',
    },
    {
        title: 'Окончание действия',
        dataIndex: 'endDate',
    },
    {
        title: 'Цена / Премия',
        dataIndex: 'premium',
    },

    {
        title: 'Программа',
        dataIndex: 'program',
    },

    {
        title: 'Страхователь',
        dataIndex: 'insurer',
    },
    {
        title: 'Банк',
        dataIndex: 'bank',
    },
    {
        title: 'Действия',
        render: (value, record) => <ContractActionsCell record={record} />,
    },
]
